import _ from "lodash";
import { chars } from "./constants";

const currencyFormatter = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0, maximumFractionDigits: 0 })

export const toRoubles = (kopeks, { separator = '.', floor = true } = {}) => {
  if (!_.isNumber(kopeks)) {
    throw new Error(`kopeks should be a number, got ${typeof kopeks}`);
  }

  const roubles = Number(Math.floor(kopeks) / 100).toFixed(floor ? 0 : 2);

  return separator !== '.' ? roubles.replace('.', separator) : roubles;
};

export const toKopeks = (roubles, { separator = '.' } = {}) => {
  if (!_.isString(roubles)) {
    throw new Error(`roubles should be string, got ${typeof roubles}`);
  }

  if (separator !== '.') {
    roubles = roubles.replace(separator, '.');
  }

  if (_.isNaN(Number(roubles))) {
    throw new Error(`can't parse ${roubles} as roubles`);
  }

  return Math.floor(Number((Number(roubles) * 100).toFixed(2)));
};

export const priceToString = (price: number, { strict = true, omitRouble = false, format = false } = {}) => price || strict ?
  `${!format ? toRoubles(price) : currencyFormatter.format(+toRoubles(price))}${omitRouble || format ? '' : chars.nbsp + chars.rouble}` :
  'Бесплатно';
