import React, { useMemo } from 'react';
import { useRouter } from "next/router";
import Head from 'next/head';
import { regionContent } from '@kursk/content/regionContent';
import { getImageUrl } from "@common/utils/image";
import { IImage } from "@common/redux/types/images";
import { getConfig } from "@root/config/config";
import { ITag } from "@common/redux/types/tags";
import {
  FilterTypes,
  filtersHas,
  getQueryFilter,
  QueryFilters,
} from "@common/query/queryFilter";
import { Page } from './seo.types';
import { useSelectSlice } from '@common/redux/selectors/useSelectSlice';
import { localesSlice } from '@common/redux/slices/locales.slice';
import { eventsPlacesSlice } from '@common/redux/slices/eventsPlaces.slice';
import { streamsPlacesSlice } from '@common/redux/slices/streamsPlaces.slice';
import { IMeta } from '@common/redux/slices/entities.slice';
import { IPlace } from '@common/redux/types/places';
import { ILocale } from '@common/redux/types/common';
import { getFiltersSeo } from './utils';

type IEntity = {
  name?: string,
  seo?: {
    title?: string,
    description?: string,
  },
  image?: IImage,
  address?: string,
  eventDate?: number,
  placeName?: string,
  eventName?: string,
  excursionDate?: number,
  tourDate?: number,
  liveDate?: number,
};

interface IProps {
  page: Page,
  entity?: IEntity;
  titleOg?: string | null;
  selectedTag?: ITag | null;
  showOgImage?: boolean;
  searchQuery?: string | string[] | null;
  meta?: IMeta
}

function getSeoMetadata(
  {
    seoPages, newFilters, page, meta,
    allEventPlaces, allStreamPlaces, allLocales,
    entity, searchQuery, selectedTag
  }:
  {
    seoPages: any, newFilters: QueryFilters,
    page: Page, meta: IMeta,
    allEventPlaces: IPlace[], allStreamPlaces: IPlace[],
    allLocales: ILocale[], entity: IEntity,
    searchQuery: string | string[], selectedTag: ITag
  }
) {
  let title: string;
  let description: string;
  if (newFilters) {
    const result = getFiltersSeo(
      newFilters,
      page,
      seoPages,
      {
        locales: allLocales,
        places: page === 'streams' || page === 'singleStream' ? allStreamPlaces : allEventPlaces,
        tags: meta.tags,
        genres: meta.genres
      }
    );
    title = result?.title;
    description = result?.description;
  }

  if (title && description) {
    return { title, description };
  }

  if (page === 'search') {
    title = entity?.seo?.title || seoPages[page].title({ searchQuery });
    description = entity?.seo?.description || seoPages[page].description({ searchQuery });
  } else if (seoPages[`${selectedTag?.sysName}/${page}`]) {
    title = seoPages[`${selectedTag?.sysName}/${page}`].title({ title: selectedTag.name }) ||
      entity?.seo?.title;
    description = seoPages[`${selectedTag?.sysName}/${page}`].description({ title: selectedTag.name }) ||
      entity?.seo?.description;
  } else if (selectedTag?.sysName && seoPages[`tagPageDefaultSeo${page}`]) {
    title = seoPages[`tagPageDefaultSeo${page}`].title({ title: selectedTag.name }) ||
      entity?.seo?.title;
    description = seoPages[`tagPageDefaultSeo${page}`].description({ title: selectedTag.name }) ||
      entity?.seo?.description;
  } else if (selectedTag?.sysName && seoPages['tagPageDefaultSeo']) {
    title = seoPages['tagPageDefaultSeo'].title({ tagName: selectedTag.name }) ||
      entity?.seo?.title;
    description = seoPages['tagPageDefaultSeo'].description({ tagName: selectedTag.name }) ||
      entity?.seo?.description;
  } else if (seoPages[page]) {
    title = entity?.seo?.title || seoPages[page].title({ entity });
    description = entity?.seo?.description || seoPages[page].description({ entity });
  }

  return { title, description };
}

export const SeoMetaTitles = ({
  page,
  entity = null,
  titleOg = null,
  selectedTag,
  showOgImage = false,
  searchQuery = null,
  meta = {},
}: IProps) => {
  const router = useRouter()
  const seoPages = regionContent.seo.pages;
  const ogEnabled = getConfig().seo.openGraph.enabled;
  const defaultSeo = {
    title: regionContent.seo.pages.index.title(),
    description: regionContent.seo.pages.index.description(),
  };
  const pageSeo = { title: null, description: null };

  const { items: allLocales } = useSelectSlice(localesSlice);
  const { items: allEventPlaces } = useSelectSlice(eventsPlacesSlice);
  const { items: allStreamPlaces } = useSelectSlice(streamsPlacesSlice);

  const { disableRobots } = getConfig()
  const isPageNoIndex = useMemo(() => {
    const newFilters = getQueryFilter(router?.query);
    return newFilters && !filtersHas(FilterTypes.TAG, newFilters);
  }, [router.query]);

  const newFilters = getQueryFilter(router.query);

  // Подстраховка на случай, если в логике seo что-то пойдет не так - в этом случае не должна падать вся страница
  try {
    const { title, description } = getSeoMetadata({
      allEventPlaces, allLocales, allStreamPlaces,
      entity, meta, newFilters, page,
      searchQuery, selectedTag, seoPages
    });
    pageSeo.title = title;
    pageSeo.description = description;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  const ogTitle = titleOg || pageSeo?.title || defaultSeo.title;

  return (
    <Head>
      <title>{pageSeo?.title || defaultSeo.title}</title>
      <meta
        name="description"
        content={pageSeo?.description || defaultSeo.description}
      />
      {ogEnabled && <meta
        property="og:title"
        content={typeof ogTitle === 'string' ? ogTitle.charAt(0).toUpperCase() + ogTitle.slice(1) : ''}
      />}
      {ogEnabled && <meta
        property="og:description"
        content={pageSeo?.description || defaultSeo.description}
      />}
      {
        (showOgImage && ogEnabled && entity?.image) && <meta
          property="og:image"
          content={`${getImageUrl(entity?.image)}`}
        />
      }
      <meta
        property='og:site_name'
        content={seoPages.index.title()}
      />
      {disableRobots && isPageNoIndex && <meta name="robots" content="noindex, nofollow"/>}
    </Head>
  );
};
