import React, { ReactElement, useEffect, useState } from 'react';
import CarouselElement, { CarouselProps, ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import classNames from "classnames";

export type ICarouselOptions = Omit<CarouselProps, 'children' | 'responsive'> & {
  responsive?: ResponsiveType;
};

interface ICarouselProps {
  Buttons?: any;
  carouselOptions?: ICarouselOptions;
  children: any;
  showButtons?: boolean;
  countable?: boolean;
  countClassStyle?: string;
  carouselRef?: (ref: any) => void;
  onIndexChange?: (index: number) => void;
}

export function Carousel({
  carouselOptions,
  Buttons,
  children,
  countable,
  countClassStyle,
  carouselRef,
  onIndexChange,
}: ICarouselProps): ReactElement {

  const defaultResponsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: 9999, min: 1200 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  };
  const [index, setIndex] = useState<number>(1);
  const responsive = carouselOptions.responsive || defaultResponsive;
  const deviceType = Object.keys(responsive).pop();

  const ref = React.useRef<CarouselElement>();
  const firstClientX = React.useRef(0);
  const firstClientY = React.useRef(0);
  const clientX = React.useRef(0);
  const clientY = React.useRef(0);

  const getCarouselRef = (innerRef) => {
    ref.current = innerRef;
    if (carouselRef) carouselRef(innerRef);
  }

  // CR-2601 - решегние взято из https://github.com/akiran/react-slick/issues/1240
  const touchStart = (e) => {
    firstClientX.current = e.touches[0].clientX;
    firstClientY.current = e.touches[0].clientY;
  }
  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX.current = e.touches[0].clientX - firstClientX.current;
    clientY.current = e.touches[0].clientY - firstClientY.current;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX.current) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }
  useEffect(() => {
    if (ref.current) {
      const container = ref.current.containerRef.current;
      container.addEventListener('touchstart', touchStart);
      container.addEventListener('touchmove', preventTouch, { passive: false });
      return () => {
        container.removeEventListener('touchstart', touchStart);
        container.removeEventListener('touchmove', preventTouch);
      }
    }
  }, [ref.current])

  return (
    <>
      {
        countable &&
        <div className={classNames(countClassStyle && countClassStyle)}>{index}/{children.length}</div>
      }
      <CarouselElement
        ref={getCarouselRef}
        beforeChange={(slideIndex, data) => {
          // Кол-во элементов в карусели может быть больше, чем мы передавали, если включен режим infinite: true
          // Offset - кол-во элементов, которые карусель создает перед основным блоком и после него
          const offset = (data.totalItems - children.length) / 2;
          const newIndex = (slideIndex - offset + children.length) % children.length;
          setIndex(newIndex + 1);
          typeof onIndexChange === 'function' && onIndexChange(newIndex);
        }}
        deviceType={deviceType}
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true}
        arrows={false}
        customButtonGroup={Buttons}
        {...carouselOptions}
      >
        {children}
      </CarouselElement>
    </>
  );
}
