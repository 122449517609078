import React from 'react';
import Image from "next/image";

import styles from './GuideGoImage.module.scss';

export type TGuideGoImageProps = {
  url: string;
  thumb: string;
}

export const GuideGoImage = ({ url, thumb }: TGuideGoImageProps) =>
  (
    <div className={styles.imageContainer}>
      <Image
        src={url}
        placeholder="blur"
        blurDataURL={thumb}
        layout='fill'
        objectFit='cover'
      />
    </div>
  );
